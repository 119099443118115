/* eslint-disable @nx/enforce-module-boundaries */
import Image from 'next/image';
import { Image as ImageChakra , Text} from '@mybridge/ui';
// import Image from 'next/image';
import PreloadImage from 'react-preload-image';
import { Box } from '@mybridge/ui/layout';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { useContext, useEffect, useRef, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { PostBoxContext } from '../context';
import styles from './child-media-player.module.scss';
import { HStack, Stack } from '@mybridge/ui/layout';

export const ChildMediaDisplay = ({ onLoaded, post }) => {
  const { handleAdClicksRouting } = useContext(PostBoxContext);
  const { gif, media, cover_image, type, title, id, ad_format_detail } = post;
  const isArticle = type === 3;
  const isAdPost = type === 4;
  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);
  const videoRef = useRef(null);
  useEffect(() => {
    prepareMedia();
  }, [media]);

  useEffect(() => {
    prepareGifs();
  }, [gif]);

  const prepareMedia = async () => {
    const vids = [];
    const imgs = [];
    for (let m of media ?? []) {
      const { file } = m ?? {};
      if (
        file?.toLowerCase?.()?.includes?.('.mp4') ||
        file?.toLowerCase?.()?.includes?.('.mov')
      ) {
        vids.push(file);
      } else {
        imgs.push(file);
      }
    }
    setVideos_(vids);
    setImages_(imgs);
  };

  const prepareGifs = async () => {
    const gs = [];
    if (gif?.length) {
      gs.push(gif);
    }
    setGifs_(gs);
  };

  const handleLoading = (val) => {
    onLoaded?.();
  };

  const handleVideoAdDuration = (duration) => {
    if(duration?.playedSeconds > 5){
      handleAdClicksRouting('video', id);
    }
  }

  // console.log('ad_format_detail?.image', videoRef);

  return (
    <>
      {videos_?.length ? (
        <Box pos="relative" overflow="hidden" className={styles.mediaPlayer}>
          <VideoPlayer
            onLoad={() => handleLoading(false)}
            src={videos_?.[0]}
            height="100%"
            getVideoDuration={(e) => console.log()}
            width="100%"
            style={{
              backgroundColor: 'black',
              // maxHeight: '70vh',
              width: '100%',
              height: '100%',
            }}
            className="player"
          />
        </Box>
      ) : (
        <>
          {isArticle ? (
            <>
              {cover_image && (
                // <Image
                //   className={styles.preloadImage}
                //   src={cover_image}
                //   lazy
                //   htmlHeight="auto"
                //   htmlWidth="100%"
                //   alt={'Post Image'}
                // />
                <Image className={styles.preloadImage} src={cover_image} alt={title} width="640" height="300" loading="lazy" />
              )}
            </>
          ) : (
            <>
              {images_?.length ? (
                images_.length > 1 ? (

                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: images_?.length > 3? 2 : 2 }}
                  >
                    <Masonry columnsCount={1} gutter="2px">
                      {images_?.map?.((img, index) => (
                        <>
                        
                        <ImageChakra key={index}  style={{
                          height: `${images_?.length ==2?'300px': (index+1 == images_?.length-1 &&images_?.length == 3)?'400px' :'200px'}`,
                          filter: `${(index+1 ==images_?.length-1 &&images_?.length > 4)?'brightness(0.5)':''}`,
                          objectFit:'cover',
                          borderRadius:"5px"
                        }}  src={img} alt={id} width="300" height="300" loading="lazy" />
                        {(index+1 ==images_?.length-1 && images_?.length > 4)&&
                          <Stack style={{position: 'absolute',top: '74%',left: '76%',transform: 'translate(-50%, -50%)',textAlign: 'center'}}>
                          <Text size="2rem" color="#ffffff">+ {images_?.length - 4}</Text>
                              </Stack>
                        }
                        
                        </>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                ) : (
                  <>
                    {/* <PreloadImage
                      className={styles.preloadImage}
                      src={images_?.[0]}
                      lazy
                    /> */}
                    <div className={styles.postImgContainer}>
                      <img
                        src={images_?.[0]}
                        alt={id}
                        className={styles.postImage}
                      />
                    </div>
                  </>
                )
              ) : (
                <>
                  {gifs_?.length ? (
                    <Box pos="relative">
                      {/* <Image
                        className={styles.preloadImage}
                        src={gifs_?.[0]}
                        lazy
                        htmlHeight="400px"
                        htmlWidth="100%"
                        alt={'Post Image'}
                      /> */}
                      <ImageChakra
                        className={styles.preloadImage}
                        src={gifs_?.[0]}
                        alt={id}
                        width="516"
                        height="516"
                        borderRadius="20px"
                        loading="lazy"
                      />
                      <></>
                    </Box>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {isAdPost && ad_format_detail?.image ? <Image className={styles.preloadImage} src={ad_format_detail?.image} alt={id} width="640" height="300" loading="lazy" /> : ''}
      {isAdPost && ad_format_detail?.video ? <Box pos="relative" overflow="hidden" className={styles.mediaPlayer}>
          <VideoPlayer
            onLoad={() => handleLoading(false)}
            src={ad_format_detail?.video}
            height="100%"
            width="100%"
            ref={videoRef}
            getVideoDuration={(e) => handleVideoAdDuration(e)}
            style={{
              backgroundColor: 'black',
              maxHeight: '70vh',
              width: '100%',
              height: '100%',
            }}
            className="player"
          />
        </Box> : ''}
    </>
  );
};
