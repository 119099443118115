/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, HStack, Square, Stack, VStack } from '@mybridge/ui/layout';
import Link from 'next/link';
import { Button } from '@mybridge/ui';
import { Text, Image } from '@mybridge/ui';
import { EventDefaultImage } from '@mybridge/icons/EventDefaultImage';
import {
  EventCalendarLineIcon,
  EventClockIcon,
  EventHostIcon,
  LinkLineIcon,
  LocationIcon,
  LocationLineIcon,
  Upload,
} from '@mybridge/icons';
import { PostBoxContext, usePostBoxContext } from '../context';
import moment from 'moment';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';

export const EventPostBody = () => {
  const { post, userProfileInfo, acceptEventLoading, acceptEvent } =
    useContext(PostBoxContext);

  const {
    id,
    title,
    author,
    excerpt,
    media,
    start_time,
    end_time,
    attendees,
    event_type,
  } = post;

  const attending = attendees?.find?.(
    (a) => a?.user?.id === userProfileInfo?.id
  );

  return (
    <>
      <HStack
        gap={3}
        mb={3}
        pt={3}
        px="16px"
        width="100%"
        alignItems="flex-start"
      >
        <Image
          src={'/'}
          alt={post?.title}
          maxW="80px"
          fallback={
            <Flex
              bg="#F4F4F4"
              width="80px"
              height="60px"
              justifyContent="center"
              alignItems="center"
            >
              {media?.length ? <div style={{width:"80px",height:"60px", overflow:"hidden"}}>
                <img src={media[0].file} style={{objectFit:"cover", width:"100%", height:"100%"}}/>
              </div>:<EventDefaultImage width="80px" height="60px" /> }
            </Flex>
          }
        />

        <VStack alignItems="flex-start" gap={2}>
          <Text
            as={Link}
            href={`/myevents/details/${id}`}
            fontWeight="medium"
            color="#5B5B5B"
            line-height="1.2"
            fontSize="15px"
          >
            {title?.length ? title?.length > 100 ? title.slice(0, 100) + "..." : title : 'Event'}
          </Text>
          {attending?.status === 'ACCEPT' ? (
            <Link href={`/myevents/details/${id}`}>
              <Button
                variant="secondary"
                color="#5B5B5B"
                size="sm"
                height="26px"
                fontSize="12px"
              >
                View details
              </Button>
            </Link>
          ) : (
            <Button
              isLoading={acceptEventLoading}
              onClick={(e) => {
                acceptEvent(post);
              }}
              variant="secondary"
              color="#5B5B5B"
              size="sm"
              height="26px"
              fontSize="12px"
            >
              {event_type === 'general' ? 'RSVP' : 'Register'}
            </Button>
          )}

          {/* {excerpt?.length ? (
                        <Box
                            color="#747474"
                            fontSize="12px"
                            dangerouslySetInnerHTML={{ __html: excerpt }}
                        />
                    ) : (
                        ''
                    )} */}
        </VStack>
      </HStack>
      <Box px="16px" pb="16px">
        {post?.author?.location && (
          <HStack>
            <LocationIcon width="14px" height="14px" />{' '}
            <Text fontSize="12px">{post?.author?.location}</Text>
          </HStack>
        )}
        {attending?.status === 'ACCEPT' ||
        userProfileInfo?.id === author?.id ? (
          <HStack>
            <LinkLineIcon width="14px" height="14px" />{' '}
            <Text fontSize="12px">
              <Link
                href={`/myevents/join/${id}`}
              >{`${process.env.BASE_PUBLIC_URL}myevents/join/${id}`}</Link>
            </Text>
          </HStack>
        ) : (
          <></>
        )}

        <HStack>
          <EventCalendarLineIcon width="14px" height="14px" />{' '}
          <Text fontSize="12px">
            {moment(start_time).local().format('MMM DD, YYYY')}
          </Text>
          <EventClockIcon width="14px" height="14px" />{' '}
          <Text fontSize="12px">
            {moment(start_time).local().format('hh:mm A')} -{' '}
            {moment(end_time).local().format('hh:mm A')}
          </Text>
        </HStack>
        <HStack>
          <EventHostIcon width="14px" height="14px" />{' '}
          <Text fontSize="12px">
            <Link href={`/p/${author?.slug}`}>{getUserFullName(author)}</Link>
          </Text>
        </HStack>
      </Box>
    </>
  );
};
